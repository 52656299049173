const initialState = {
  dashboard: {},
  products: [],
  unmappedProducts: [],
  providers: [],
  users: [],
  brewers: [],
  providerDetail: {},
  productDetail: {},
  unMappedProductDetail: {},
  userDetail: {},
  brewerDetail: {},
  params: {},
  totalProducts: 0,
  totalUnmappedProducts: 0,
  totalProviders: 0,
  totalBrewers: 0, 
  productsSelcted: [],
  unmappedProductsSelected: [],
  brewersSelcted: []
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case 'ADMIN_GET_PRODUCTS':
      return { ...state, products: action.data.products, params: action.params, totalProducts: action.data.total }
    case 'ADMIN_GET_UNMAPPED_PRODUCTS':
      return { ...state, unmappedProducts: action.data.products, params: action.params, totalUnmappedProducts: action.data.total }
    case 'GET_PROVIDERS':
      return { ...state, providers: action.data.providers, params: action.params, totalProviders: action.data.total }
    case 'ADMIN_GET_PRODUCT':
      return { ...state, productDetail: action.data }
    case 'ADMIN_GET_UNMAPPED_PRODUCT':
      return { ...state, unMappedProductDetail: action.data }
    case 'GET_PROVIDER':
      return { ...state, providerDetail: action.data }
    case 'GET_USERS':
      return { ...state, users: action.data.users, params: action.params, totalUsers: action.data.total }
    case 'GET_USER':
      return { ...state, userDetail: action.data }
    case 'GET_BREWERS':
      return { ...state, brewers: action.data.brewers, params: action.params, totalBrewers: action.data.total }
    case 'GET_BREWER':
      return { ...state, brewerDetail: action.data }
    case 'GET_DASHBOARD':
      return { ...state, dashboard: action.data }
    case 'UPDATE_BREWER_SELECTION':
      return { ...state, brewersSelcted: action.data }
    case 'UPDATE_PRODUCT_SELECTION':
      return { ...state, productsSelcted: action.data }
    case 'UPDATE_PRODUCT_UNMAPPED_SELECTION':
      return { ...state, unmappedProductsSelected: action.data }
    default:
      return state
  }
}

export default admin

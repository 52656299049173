// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  },
  { 
    action: "manage", 
    subject: "all"
  }
]

export const _ = undefined

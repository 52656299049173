// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Initial ability & context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { IntlProviderWrapper } from "./utility/context/Internationalization";

// ** Apollo GraphQL imports
import { HttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

// ** Various imports
import Axios from "axios";
import jwtDefaultConfig from "./@core/auth/jwt/jwtDefaultConfig";

const GRAPHQL_URL = `${process.env.REACT_APP_API_ENDPOINT}/graphql/query`;
const REFRESH_URL = `${process.env.REACT_APP_API_ENDPOINT}/auth/refresh`;

const refreshToken = () => {
  const refreshToken = JSON.parse(
    localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName)
  );
  if (refreshToken && refreshToken === "") {
    return;
  }
  return Axios.post(
    REFRESH_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  )
    .then((resp) => {
      if (resp.status === 200) {
        localStorage.setItem(
          jwtDefaultConfig.storageTokenKeyName,
          JSON.stringify(resp.data.access_token)
        );
        localStorage.setItem(
          jwtDefaultConfig.storageRefreshTokenKeyName,
          JSON.stringify(resp.data.refresh_token)
        );
        localStorage.setItem(
          jwtDefaultConfig.storageExpireDate,
          JSON.stringify(resp.data.expire_date)
        );
        return resp.data.access_token;
      }
    })
    .catch((err) => {
      console.log(err);
      localStorage.removeItem("userData");
      window.location.href = "/login/pwdless";
    });
};

const AuthLink = setContext(async (_, { headers }) => {
  // If x-role anon header present continue
  if (headers && headers["X-Role"] === "anon") {
    return {
      headers: {
        ...headers,
      },
    };
  }
  // return the headers to the context so httpLink can read them
  const expireDate = new Date(
    JSON.parse(localStorage.getItem(jwtDefaultConfig.storageExpireDate))
  );

  const expirationTime = expireDate.getTime() / 1000 - 60000;
  if (Date.now() / 1000 - 60000 >= expirationTime) {
    await refreshToken();
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
      )}`,
    },
  };
});

const LogoutLink = onError(({ networkError }) => {
  if (networkError.statusCode === 401) {
    localStorage.removeItem("userData");
    window.location.href = "/login/pwdless";
  }
});

const additiveLink = from([
  AuthLink,
  LogoutLink,
  new HttpLink({
    uri: GRAPHQL_URL,
  }),
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
});

// ** Init Google Analytics

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <ApolloProvider client={client}>
              <LazyApp />
              <ToastContainer newestOnTop />
            </ApolloProvider>
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
